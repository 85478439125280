/* Timer.css */
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}
.container {
    border:5px solid #333259;
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto;
    width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #9F96D9;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

h1 {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
}

button {
    background-color: white;
    border: none;
    color: #2B3040;
    font-size: 1.2em;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #535391;
}

button:disabled {
    background-color: #333259;
    color: white;
    cursor: not-allowed;
}

div {
    text-align: center;
}

.timer-display{
    font-size: 4em;
    font-weight: bold;
    margin: 20px 0;
    padding: 20px;
    background-color: #9F96D9;
    color: white;
    border-radius: 10px;
    display: inline-block;
    width: 200px;
    text-align: center;
}

.button-group {
    display: flex;
    justify-content: center;

}

.tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}
.tabs button {
    padding: 8px 16px;
    font-size: 1.1em;
}

.tabs button.active {
    background-color: #11557C;
    color: white;
    font-weight: bold;
}

@media (max-width: 768px) {
    .timer-display{
        font-size: 70px;
        margin: 15px auto;
        text-align: center;
    }

    .container {
        width: 90%;
        max-width: 350px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

    }

    h1 {
        margin-bottom: 30px;
        text-align: center;
    }

    .tabs{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .tabs button {
        font-size: 15px;
        padding: 10px 15px;
        margin: 0 5px;
    }

    .button-group {
        display: flex;
        padding: 10px 15px;
        text-align: center;
        font-size: 15px;
    }



}