.App {
  text-align: center;
}

.app-container {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 20px;
}

/* Header style*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #333259;
  padding: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;

}

.content {
  margin-top: 200px;
  padding: 20px;
  margin-bottom: 50px;
}

.table-container {
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  margin: 0 auto;
}

.header h1 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Navigation button styles*/
.nav-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}


.nav-buttons button {
  background-color: #333259;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-buttons button:hover {
  color: #9F96D9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.footer {
  background-color: #333259;
  color: white;
  text-align: center;
  padding: 10px 20px 10px 10px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .header{
    padding: 10px 0;
    font-size: 18px;
  }

  .content {
    margin-top: 120px;
    padding: 10px;
  }

  .footer {
    font-size: 12px;
    text-align: center;
    padding: 10px 0;
    position: fixed;
  }



}
