table {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    width: 80%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
}

/* Add spacing and borders to table cells */

th, td{
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    padding: 12px 15px;
    border: 2px solid #333259;
}

/* Add background color for the table header*/
thead tr {
    background-color: #333259;
}

/* Add hover effect for table rows */
tbody tr:hover {
    background-color: #535391;
}

/* Center the activity log title*/
h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 40px;
}

/* Add some margin to the overall table container */
table {
    margin: auto;
}